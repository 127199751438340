import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import "bootstrap/dist/css/bootstrap.min.css";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

/* import specific icons */
// import {
//   faPaperPlane,
//   faHeart,
//   faSearch,
//   faMessage,
//   faSignal,
//   faChartLine,
//   faFileWord,
// } from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
library.add(
  // faPaperPlane,
  // faHeart,
  // faSearch,
  // faMessage,
  // faSignal,
  // faChartLine,
  // faTiktok,
  // faFileWord,
  far,
  fab,
  fas
);

createApp(App)
  .use(router)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
