<template>
  <div class="landing-page">
    <div class="page-wrapper">

      <!-- ******Header****** -->
      <header class="header text-center">
        <div class="container">
          <div class="branding">
            <h1 class="logo">
              <span aria-hidden="true" class="icon_documents_alt icon"></span>
              <span class="text-highlight">盟友基地</span><span class="text-bold">-API</span>
            </h1>
          </div><!--//branding-->
          <div class="tagline">
            <p>免费的api接口</p>
            <p>仅供个人研究测试使用
              <FontAwesomeIcon icon="fa fa-heart" />请不要将接口用于非法用途
            </p>
          </div><!--//tagline-->

          <div class="main-search-box pt-3 pb-4 d-inline-block">
            <form class="form-inline search-form justify-content-center" @submit.prevent="search" action="" method="get">

              <input type="text" placeholder="根据关键词快速查找" v-model="keyword" name="search"
                class="form-control search-input">

              <button type="submit" class="btn search-btn" value="Search">
                <FontAwesomeIcon icon="fa fa-search" />
              </button>

            </form>
          </div>

          <div class="social-container">
            <!-- Replace with your Github Button -->
            <div class="github-btn mb-2">
              <div class="info-intro">
                <p>遇到接口异常可以反馈给管理员，快速修复问题 </p><a href="mailto:admin@mymyjd.cn?subject=问题反馈【api.mymyjd.cn】">
                  发送邮件
                  <FontAwesomeIcon icon="fa fa-message" />
                </a>

              </div>
            </div>
          </div><!--//social-container-->


        </div><!--//container-->
      </header><!--//header-->

      <section class="cards-section text-center">
        <div class="container">

          <div id="cards-wrapper" class="cards-wrapper row">
            <div :class="itemColor[item.id % 6]" class="item col-lg-4 col-6" v-for="item in data" :key="item.id">
              <div class="item-inner">
                <div class="icon-holder">
                  <FontAwesomeIcon :icon="item.icon" size="3x" />
                </div><!--//icon-holder-->
                <h3 class="title">
                  {{ item.name }}
                </h3>
                <p class="intro desc">
                  {{ item.desc }}
                </p>
                <div class="data-box">
                  <span>
                    <FontAwesomeIcon data-bs-placement="top" data-bs-toggle="tooltip" data-bs-title="响应速度"
                      icon="fa fa-signal" size="sm" style="margin-right:2px;" />{{ item.exec_time }}ms
                  </span>

                  <span>
                    <FontAwesomeIcon icon="fa fa-chart-line" size="sm" /> {{ item.times }}
                  </span>
                </div>
                <router-link class="link" :to="'docs/' + item.id"><span></span></router-link>

              </div><!--//item-inner-->
            </div><!--//item-->
            <p v-show="isNull">没有更多了</p>
          </div><!--//cards-->

        </div><!--//container-->
      </section><!--//cards-section-->
    </div><!--//page-wrapper-->

    <footer class="footer text-center">
      <div class="container">
        <small class="copyright">盟友基地<font-awesome-icon icon="fa-solid fa-heart" size="lg" />
          * <a target="_blank" href="http://beian.miit.gov.cn/" title="网页模板">豫ICP备15033949号-1</a> for developers
        </small>&nbsp;&nbsp;<a target="_blank" href="https://u18.ink" title="抖音作品批量下载">抖音作品批量下载工具</a>

      </div><!--//container-->
    </footer><!--//footer-->
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

import axios from "axios"

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


export default {
  name: 'HomeView',
  components: {
    FontAwesomeIcon
  },
  mounted() {

    axios.get("https://api.mymyjd.cn/api/list").then((res) => {
      this.data = this.rowdata = res.data
    })
  },
  computed: {
    isNull() {
      return this.data.length == 0;
    }
  },
  data() {
    return {
      keyword: '',
      rowdata: [],
      itemColor: [
        "item-red",
        "item-green",
        "item-pink",
        "item-blue",
        "item-purple",
        "item-primary",
        "item-orange"
      ],
      data: [
        {
          id: 0,
          name: "demo",
          desc: "这是一个demo接口",
          icon: "heart",
          times: 19999
        }, {
          id: 1,
          name: "demo",
          desc: "这是一个demo接口",
          icon: "heart",
          times: 19999
        },
        {
          id: 2,
          name: "demo",
          desc: "这是一个demo接口",
          icon: "heart",
          times: 19999
        }, {
          id: 3,
          name: "demo",
          desc: "这是一个demo接口",
          icon: "heart",
          times: 19999
        }
      ]
    }
  },
  methods: {
    search() {
      if (this.keyword == "") {
        this.data = this.rowdata
        return;
      }
      this.data = this.filterSearch(this.rowdata, this.keyword, "name")
      if (this.data.length == 0) {
        this.showNull = true
      }
    },
    filterSearch(targetArray = [], keyword = "", key = "name") {
      if (keyword == "") {
        return targetArray
      } else {
        return targetArray.filter((value) => {
          return this.convert2low(value[key]).indexOf(this.convert2low(keyword)) !== -1;
        });
      }
    },
    convert2low(value) {
      return String(value).toLowerCase()
    }
  }
}
</script>

<style>
.data-box {
  display: flex;
  justify-content: space-between;
  align-items: bottom;
}

.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 75px;
}
</style>